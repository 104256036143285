import { Navbar, Footer } from "../";
import "./styles.css";

const ErrorPage = () => {
  return (
    <div className="error-body">
      <Navbar />

      <div className="error-body-inner">
        <div className="error-page-content-container">
          <p>Sivua ei löydy</p>
          <h1>Error 404</h1>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ErrorPage;
