import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import { AnimatedRoutes } from "./components";

import "./App.css";

const CreateRoutes = () => {
  return (
    <>
      <Router>
        <AnimatedRoutes />
      </Router>
    </>
  );
};

class App extends React.Component {
  componentDidMount() {
    const root = createRoot(document.getElementById("app-id"));
    root.render(CreateRoutes());
  }

  render() {
    return <div className="app" id="app-id"></div>;
  }
}

export default App;
