import "./styles.css";
import { Navbar, Footer } from "../";

import mail from "../../img/mail.png";
import phone from "../../img/phone.png";
import location from "../../img/location.png";

import send from "../../img/send.png";

import { motion } from "framer-motion";

const OtaYhteytta = () => {
  return (
    <div className="contact-page-container">
      <Navbar />
      <motion.div className="contant-container">
        <div className="contact-header-container">
          <h1>Ota Yhteyttä</h1>
        </div>
        <div className="contact-form-container">
          <div className="contact-form-left">
            <div>
              <h1>Me autamme</h1>
              <div className="group-8242">
                <div className="group-824-inner-2">
                  <div className="group-824-inner-right">
                    <img
                      src={mail}
                      alt="Not Found"
                      className="footer-img"
                      draggable="false"
                    />
                    <p className="sposti-cityinfra-fi">
                      asiakaspalvelu@cityinfra.fi
                    </p>
                  </div>
                </div>

                <div className="group-824-inner-2">
                  <div className="group-824-inner-right">
                    <img
                      src={phone}
                      alt="Not Found"
                      className="footer-img"
                      draggable="false"
                    />
                    <p className="_-0411231234">041 313 5420</p>
                  </div>
                </div>
                <div className="group-824-inner-2">
                  <div className="group-824-inner-right">
                    <img
                      src={location}
                      alt="Not Found"
                      className="footer-img"
                      draggable="false"
                    />
                    <p className="osoite-1212345-kunta">
                      Myllykyläntie 357, Tuusula
                    </p>
                  </div>
                </div>
              </div>
              <div className="contact-info-container">
                <b>Verkkolaskutus</b>
                <br />
                Verkkolaskutunnus: 003731648192
                <br />
                Välittäjätunnus: 003708599126 (Liaison)
                <br />
                Verkkolaskuoperaattori: Liaison
                <br />
                Technologies Oy
                <br />
                <br />
                <b>Sähköpostitse</b>
                <br />
                fennoa.FI.P.143769-8@docinbound.com
                <br />
                <br />
                <b>Postitse</b>
                <br />
                Cityinfra Oy
                <br />
                PL 52451
                <br />
                01051 LASKUT
                <br />
              </div>
            </div>
          </div>
          <div className="contact-form-right">
            <form
              action="mailto:asiakaspalvelu@cityinfra.fi"
              method="get"
              enctype="text/plain"
              className="form-container"
            >
              <div className="form-details-container">
                <div className="form-details-inner">
                  <div>
                    <p>Nimi</p>
                    <input type="text" name="name" placeholder="Nimi" />
                  </div>
                  <div>
                    <p>Sähköposti</p>
                    <input type="email" name="email" placeholder="Sähköposti" />
                  </div>
                </div>
                <div className="form-details-inner">
                  <div>
                    <p>Puhelinnumero</p>
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Puhelinnumero"
                    />
                  </div>
                  <div>
                    <p>Aihe</p>
                    <input type="text" name="subject" placeholder="Aihe" />
                  </div>
                </div>
              </div>
              <div className="form-message-container">
                <p>Viesti</p>
                <textarea
                  name="body"
                  rows="5"
                  cols="30"
                  placeholder="Kerro meille lisää"
                ></textarea>
              </div>
              <div className="form-button-container">
                <div
                  className="form-send-button"
                  onClick={() => document.getElementById("send-mail").click()}
                >
                  <p>Lähetä</p>
                  <img
                    src={send}
                    alt="Not Found"
                    className="send-img"
                    draggable="false"
                  />
                  <input
                    type="submit"
                    style={{ display: "none" }}
                    id="send-mail"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default OtaYhteytta;
