import React from "react";
import { Navbar, Footer } from "../";
import "./styles.css";
import { NavLink } from "react-router-dom";

import shield from "../../img/shield.png";
import smile from "../../img/smile.png";
import graph from "../../img/graph.png";

import excav from "../../img/excav.png";

import { motion } from "framer-motion";

const Home = () => {
  return (
    <div className="frame-3 clip-contents">
      <Navbar />
      <motion.div id="first-page" className="page">
        <div id="page-inner-first" className="page-inner">
          <div className="page-inner-title">
            <div className="inner-title-container">
              <h1 className="page-inner-header">
                Pieninfran moniosaaja <br />
                Uudellamaalla<span className="color-red">.</span>
              </h1>
              <p>
                Olemme erikoistuneet pienmaanrakennus töihin Uudenmaan ja
                pääkaupunkiseudun alueella.
              </p>
            </div>
            <div className="page-inner-buttons-container">
              <a
                className={"button-left button-marked"}
                href="tel:041-313-5420"
              >
                Soita
              </a>
              <NavLink
                className="button-right"
                to="/palvelut"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Palvelut
              </NavLink>
            </div>
          </div>
        </div>
        <div id="page-inner-second" className="page-inner">
          <div className="inner-subs">
            <div className="inner-subs-container">
              <img
                src={smile}
                alt="Not Found"
                className="thumb-_up"
                draggable="false"
              />

              <h1 className="inner-subs-text">Helposti</h1>
            </div>
            <div className="inner-subs-container">
              <img
                src={graph}
                alt="Not Found"
                className="group"
                draggable="false"
              />

              <h1 className="inner-subs-text">Kustannustehokkaasti</h1>
            </div>
            <div className="inner-subs-container">
              <img
                src={shield}
                alt="Not Found"
                className="circle-_right-_alt"
                draggable="false"
              />
              <h1 className="inner-subs-text">Luotettavasti</h1>
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div id="second-page" className="page">
        <div className="page-inner-bottom">
          <div className="bottom-text-container">
            <h1 className="bottom-text-title">
              Asiakaslähtöistä palvelua <br />
              huippu osaamisella.{" "}
            </h1>
            <p className="bottom-text-bread">
              Pieninfraan erikoistumisella kykenemme kustannustehokkaaseen,
              ripeään, sekä laadukkaaseen lopputulokseen. Annamme aina tarkan
              aikataulun ja kustannusarvion.
            </p>
            <div
              className="button-ota-yhteytt"
              onClick={() => {
                window.scrollTo(0, 0);
                document.getElementById("ota-yht").click();
              }}
            >
              <p>Ota yhteyttä</p>
            </div>
            <div className="button-phone-view">
              <div
                onClick={() => {
                  window.scrollTo(0, 0);
                  document.getElementById("ota-yht").click();
                }}
              >
                <NavLink id="ota-yht" to="/ota-yhteytta">
                  Ota yhteyttä
                </NavLink>
              </div>
            </div>
          </div>

          <img
            src={excav}
            alt="Not Found"
            className="group-71"
            draggable="false"
          />
        </div>
      </motion.div>

      <Footer />
    </div>
  );
};

export default Home;
