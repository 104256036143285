import React from "react";
import { Navbar, Footer } from "../";
import "./styles.css";
import { NavLink } from "react-router-dom";

import arrow from "../../img/arrow.png";

import { motion } from "framer-motion";

const Palvelut = () => {
  return (
    <div className="page-container">
      <Navbar />
      <motion.div className="page-palvelut">
        <div className="palvelut-header-container">
          <h1>Palvelut</h1>
        </div>

        <div className="palv-image-container">
          <div id="palv-img-1" className="palv-img">
            <h1>Huolto</h1>
            <div className="arrow-container">
              <NavLink
                to="/ota-yhteytta"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Kysy lisää
              </NavLink>
              <img
                src={arrow}
                alt="Not Found"
                className="arrow-img"
                draggable="false"
              />
            </div>
          </div>
          <div id="palv-img-2" className="palv-img">
            <h1>Pien Korjaus/Asennustyöt</h1>
            <div className="arrow-container">
              <NavLink
                to="/ota-yhteytta"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Kysy lisää
              </NavLink>
              <img
                src={arrow}
                alt="Not Found"
                className="arrow-img"
                draggable="false"
              />
            </div>
          </div>
          <div id="palv-img-3" className="palv-img">
            <h1>Pien Maanrakennustyöt</h1>
            <div className="arrow-container">
              <NavLink
                to="/ota-yhteytta"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Kysy lisää
              </NavLink>
              <img
                src={arrow}
                alt="Not Found"
                className="arrow-img"
                draggable="false"
              />
            </div>
          </div>
        </div>
        <div className="boxes-container">
          <h1>Asiakkaita pieninfralle ovat</h1>
          <div className="boxes-container-inner">
            <div className="inner-box">
              <h1 className="inner-box-number">1</h1>
              <p>Yritykset</p>
            </div>
            <div className="inner-box">
              <h1 className="inner-box-number">2</h1>
              <p>Taloyhtiöt</p>
            </div>
            <div className="inner-box">
              <h1 className="inner-box-number">3</h1>
              <p>Kaupungit ja kunnat</p>
            </div>
            <div className="inner-box">
              <div className="inner-box-number">
                <h1>4</h1>
              </div>
              <p>Yksityiset</p>
            </div>
          </div>
        </div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default Palvelut;
