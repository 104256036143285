import { NavLink } from "react-router-dom";

import "./styles.css";

import logo from "../../img/logo.png";
import mail from "../../img/mail.png";
import phone from "../../img/phone.png";
import location from "../../img/location.png";

const Footer = () => {
  return (
    <div className="group-16">
      <div className="group-1095">
        <div className="group-211">
          <div className="logo">
            <img
              src={logo}
              alt="Not Found"
              className="cityinfra"
              draggable="false"
            />
          </div>
          <div className="copyright-desktop">
            Copyright 2023 © All rights Reserved. Tietosuojaseloste
          </div>
        </div>
        <div className="group-824">
          <div className="group-824-inner">
            <div className="group-824-inner-left">
              <NavLink
                className="etusivu"
                to="/"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Etusivu
              </NavLink>
            </div>
            <div className="group-824-inner-right">
              <img
                src={mail}
                alt="Not Found"
                className="footer-img"
                draggable="false"
              />
              <p className="sposti-cityinfra-fi">asiakaspalvelu@cityinfra.fi</p>
            </div>
          </div>

          <div className="group-824-inner">
            <div className="group-824-inner-left">
              <NavLink
                to="/palvelut"
                className="palvelut"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Palvelut
              </NavLink>
            </div>
            <div className="group-824-inner-right">
              <img
                src={phone}
                alt="Not Found"
                className="footer-img"
                draggable="false"
              />
              <p className="_-0411231234">041 313 5420</p>
            </div>
          </div>
          <div className="group-824-inner">
            <div className="group-824-inner-left">
              <NavLink
                to="/ota-yhteytta"
                className="ota-yhteytt"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Ota yhteyttä
              </NavLink>
            </div>
            <div className="group-824-inner-right">
              <img
                src={location}
                alt="Not Found"
                className="footer-img"
                draggable="false"
              />
              <p className="osoite-1212345-kunta">Myllykyläntie 357, Tuusula</p>
            </div>
          </div>
        </div>
        <div className="group-824-phone">
          <div className="group-824-inner">
            <div>
              <NavLink
                className="etusivu"
                to="/"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Etusivu
              </NavLink>
            </div>
            <div>
              <NavLink
                to="/palvelut"
                className="palvelut"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Palvelut
              </NavLink>
            </div>
            <div>
              <NavLink
                to="/ota-yhteytta"
                className="ota-yhteytt"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Ota yhteyttä
              </NavLink>
            </div>
          </div>
          <div className="group-824-inner">
            <div className="group-824-inner-right">
              <img
                src={mail}
                alt="Not Found"
                className="footer-img"
                draggable="false"
              />
              <p className="sposti-cityinfra-fi">asiakaspalvelu@cityinfra.fi</p>
            </div>
            <div className="group-824-inner-right">
              <img
                src={phone}
                alt="Not Found"
                className="footer-img"
                draggable="false"
              />
              <p className="_-0411231234">041 313 5420</p>
            </div>
            <div className="group-824-inner-right">
              <img
                src={location}
                alt="Not Found"
                className="footer-img"
                draggable="false"
              />
              <p className="osoite-1212345-kunta">Myllykyläntie 357, Tuusula</p>
            </div>
          </div>
          <div>
            <p className="copyright-2023-all-righ">
              Copyright 2023 © All rights Reserved.
            </p>
            &thinsp; &thinsp; &thinsp; &thinsp;
            <p className="tietosuojaseloste">Tietosuojaseloste</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
