import { Routes, Route, useLocation } from "react-router-dom";
import { Home, Palvelut, OtaYhteytta, ErrorPage } from "../components";

import { AnimatePresence } from "framer-motion";

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" index element={<Home />} />
        <Route path="/palvelut" element={<Palvelut />} />
        <Route path="/ota-yhteytta" element={<OtaYhteytta />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
