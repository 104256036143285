import { NavLink } from "react-router-dom";
import { useState } from "react";
import Fade from "@mui/material/Fade";

import "./styles.css";
import logo from "../../img/logo.png";
import menu from "../../img/menu.png";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <div className="navbar">
      <div className="logo-1">
        <img
          src={logo}
          alt="Not Found"
          className="cityinfra-4"
          draggable="false"
        />
      </div>
      <div className="group-937">
        <NavLink
          className="nav-element"
          to="/"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          Etusivu
        </NavLink>
        <NavLink
          className="nav-element"
          to="/palvelut"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          Palvelut
        </NavLink>
      </div>
      <div className="group-163">
        <NavLink
          className="palvelut-2"
          to="/ota-yhteytta"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          Ota yhteyttä
        </NavLink>
      </div>

      <div className="menu-container">
        <button
          className="menu-icon-container"
          onClick={() => {
            setIsMobileMenuOpen(!isMobileMenuOpen);
            document.body.style.overflow = isMobileMenuOpen
              ? "unset"
              : "hidden";
          }}
        >
          <img
            src={menu}
            alt="Not Found"
            className="menu-icon"
            draggable="false"
          />
        </button>
        <ul
          className={`${
            isMobileMenuOpen ? "menu-options-container" : "hidden"
          }`}
        >
          <li>
            <NavLink
              to="/"
              onClick={() => {
                if (window.location.pathname !== "/") {
                  document.body.style.overflow = "unset";
                  window.scrollTo(0, 0);
                }
              }}
            >
              Etusivu
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/palvelut"
              onClick={() => {
                if (window.location.pathname !== "/palvelut") {
                  document.body.style.overflow = "unset";
                  window.scrollTo(0, 0);
                }
              }}
            >
              Palvelut
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/ota-yhteytta"
              onClick={() => {
                if (window.location.pathname !== "/ota-yhteytta") {
                  document.body.style.overflow = "unset";
                  window.scrollTo(0, 0);
                }
              }}
            >
              Ota yhteyttä
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
